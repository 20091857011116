import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Home from "../components/home";
import About from "../components/about";
import Services from "../components/services";
import Contact from "../components/contact";

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[`App-Development`]} title="Home" />

      <Home />
      <About />
      <Services />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
