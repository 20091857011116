import * as React from "react";

const About = () => {
  return (
    <section id="about" className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-12">
        <div>
          <h2>Hallo, ich bin Tobi!</h2>
          <h3>Web Developer aus Siegen</h3>
          <p>
            Seit über 20 Jahren beschäftige ich mich mit Entwicklung und Betrieb
            von Webanwendungen auf Basis unterschiedlicher Technologien.
            Vorwiegend nutze ich dabei Angular, React &amp; NodeJS - bin aber
            auch mit PHP und unterschiedlichen Datenbankmanagement-Systemen
            &quot;per du&quot;.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
