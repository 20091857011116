import * as React from "react";

const Services = () => {
  return (
    <section id="services" className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center md:text-left">
        <h2>Services</h2>
        <div className="grid lg:grid-cols-3 gap-x-12 gap-y-20 md:grid-cols-2 grid-cols-1">
          <div className="flex flex-col items-center md:items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <h3 className="mt-4">Web-Development</h3>
            <p>
              Aufgrund meiner langjährigen Erfahrung in verschiedenen Projekten
              und Teams kann ich Sie optimal in Ihrem Entwicklungsprozess
              unterstützen.
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
                clipRule="evenodd"
              />
            </svg>
            <h3 className="mt-4">Mobile-Apps</h3>
            <p>
              Auch mobile Apps sind mit Web-Technologien umsetzbar. Der Vorteil
              ist, dass man größtenteils Systemunabhängig bleibt und die gleiche
              App nicht mehrfach entwickeln lassen muss.
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                clipRule="evenodd"
              />
            </svg>
            <h3 className="mt-4">Front-End</h3>
            <p>
              Jede Webanwendung verdient ein schickes Frontend. Hierfür nutze
              ich überwiegend Angular, habe jedoch auch schon andere Frameworks
              wie beispielsweise vue, react, next.js &amp; gatsby genutzt
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2h3a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2h3a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                clipRule="evenodd"
              />
            </svg>
            <h3 className="mt-4">Back-End</h3>
            <p>
              Egal ob NodeJS oder PHP - ich habe schon etliche Backends gebaut.
              Vom kleinen CMS bis hin zu High-Availability-APIs war alles dabei.
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <h3 className="mt-4">Hosting</h3>
            <p>
              Eine Webanwendung muss erreichbar sein. Ich kümmere mich um
              Hosting, Betrieb &amp; Monitoring Ihrer Applikation.
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h3v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h3.002z" />
            </svg>
            <h3 className="mt-4">SEO</h3>
            <p>
              In Suchmaschinen gefunden zu werden wird immer wichtiger. Gerne
              unterstütze ich dabei, Ihre Seite dahingehend zu optimieren.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
