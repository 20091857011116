import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import bgVideoMp4 from "../assets/bgvideo.mp4"
import bgVideoOgg from "../assets/bgvideo.ogv"
import bgVideoWebm from "../assets/bgvideo.webm"
import * as ReactRotatingText from "react-rotating-text"
import { Link } from "react-scroll"

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      me: file(relativePath: { eq: "me_cartoon.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 170, height: 170, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      videoPoster: file(relativePath: { eq: "bgvideo_poster.png" }) {
        childImageSharp {
          fixed(width: 1920, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="h-screen relative w-full bg-no-repeat">
        <div className="absolute w-full h-full left-0 top-0 overflow-hidden opacity-100 transition-opacity duration-1000 z-0 video-container">
          <video
            autoPlay
            loop
            muted
            preload="auto"
            className="absolute z-10 w-auto min-w-full min-h-full max-w-none filter brightness-50 contrast-50"
            poster={data.videoPoster.childImageSharp.fixed.srcWebp}
          >
            <source src={bgVideoMp4} type="video/mp4"></source>
            <source src={bgVideoOgg} type="video/ogg"></source>
            <source src={bgVideoWebm} type="audio/webm"></source>
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="table w-full h-full">
          <div className="table-cell align-middle">
            <div
              className="absolute text-center text-white"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
              }}
            >
              <Img
                fixed={data.me.childImageSharp.fixed}
                alt="Tobias Brandenberg"
                className="w-16 border-4 rounded-full mx-auto border-white"
              />

              <div>
                <h1 className="text-6xl my-8" style={{ fontFamily: "Hind" }}>
                  Hi, ich bin Tobi!
                </h1>
                <ReactRotatingText
                  className="text-3xl opacity-50"
                  items={["Web-Developer", "Software-Engineer", "Full-Stack"]}
                />
              </div>
            </div>

            <Link to="about" spy={true} smooth={true} duration={500}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 absolute bottom-16 mx-auto right-0 left-0 animate-bounce text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home

/*
<iframe
            id="iframe_bg-youtube"
            className="absolute z-0 w-screen h-screen top-0 left-0 overflow-hidden opacity-100 select-none"
            style={{
              width: "2000px",
              height: "1000px",
              marginLeft: "-400px",
            }}
            frameBorder="0"
            allowFullScreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
            width="640"
            height="360"
            src="https://www.youtube-nocookie.com/embed/T2Y0LDASIyo?playlist=T2Y0LDASIyo&modestbranding=1&autoplay=0&controls=0&showinfo=0&rel=0&enablejsapi=1&version=3&playerapiid=iframe_bg-youtube&allowfullscreen=true&wmode=transparent&iv_load_policy=3&playsinline=0&html5=1&widgetid=1&loop=1"
            unselectable="on"
          ></iframe>
*/
