import React, { useState } from "react";

const THIS_PAGE = "https://contact.webwerker.io";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [statusText, setStatusText] = useState("");

  const handleChange = (event) => {
    const key = event.target.name;
    const updatedFormValue = event.target.value;
    const newFormData = { ...formData, [key]: updatedFormValue };
    setFormData(newFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    fetch(THIS_PAGE, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": form.getAttribute("name"),
        ...formData,
      }).toString(),
    })
      .then((response) => {
        //where your custom handling goes
        if (!response.ok) throw Error(response.statusText);

        setFormData({ name: null, email: null, message: null });

        setStatusText("Vielen Dank! Ihre Nachricht wurde verschickt");
      })
      .catch((error) => setStatusText(`Fehler: ${error.message}`));
  };

  return (
    <section id="contact" className="bg-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2>Kontakt</h2>

        <div className="lg:grid lg:grid-cols-2 lg:max-w-5xl mx-auto">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto my-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>

            <p>
              <a href="mailto:hallo@webwerker.io">hallo@webwerker.io</a>
            </p>
          </div>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto my-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>

            <p>
              <a href="tel:+4927131928567">+49 (0) 271 - 319 28 567</a>
            </p>
          </div>
        </div>
   
      </div>
    </section>
  );
};

export default Contact;
